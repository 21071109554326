<template>
    <div style="font-size: 12px">
        <div class="bg-white min-h-screen text-black">
            <div class="grid grid-cols-12 h-full gap-5">
                <div class="col-span-1 relative flex  justify-center">
                    <div class="absolute transform -rotate-90 whitespace-nowrap text-4xl top-64">Packing List <span class="text-red-600 ml-5">#{{suratJalan}}</span></div>
                </div>
                <div class="col-span-11 mr-5">
                    <div v-if="company" class="flex border-b-2 border-red-600 pb-2">
                        <div>
                            <img :src="imgURL+'/public/img/company-logo.png'" width="80" alt="">
                        </div>
                        <div>
                            <div class="text-2xl font-bold text-red-600">{{company.name}}</div>
                            <div class="text-base italic font-semibold" style="color: blue;">{{company.tagline}}</div>
                            <div>{{company.companyAddress.address}} {{company.companyAddress.city.name}} {{company.companyAddress.postal_code}}</div>
                        </div>
                    </div>
                    <div class="grid grid-cols-12 gap-5 mt-3">
                        <div class="col-span-3">
                            <div class="text-red-600">Date</div>
                            <div>{{getDate(date)}}</div>
                            <div class="text-red-600 mt-2">Ship Via</div>
                            <div>{{shipVia}}</div>
                        </div>
                        <div class="col-span-9">
                            <div class="grid grid-cols-2 gap-5">
                                <div class="col-span-1">
                                    <div class="text-red-600">Customer PO</div>
                                    <div>{{customerPO}}</div>
                                </div>
                                <div class="col-span-1">
                                    <div class="text-red-600">To</div>
                                    <div class="font-semibold">{{shipTo.name}}</div>
                                    <div>{{shipTo.address.street}}</div>
                                    <div>{{shipTo.address.street2}}</div>
                                    <div>{{shipTo.address.subdistrictName}} {{shipTo.address.cityName}} {{shipTo.address.provinceName}}</div>
                                    <div>Telp: {{shipTo.contact.phone || shipTo.contact.mobile}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-3">
                        <table class="w-full">
                            <thead>
                                <tr>
                                    <th class="px-2 py-1 bg-red-600 text-red-50" style="width: 8%">Quantity</th>
                                    <th class="px-2 py-1 bg-red-600 text-red-50" style="width: 60%">Description</th>
                                </tr>
                            </thead>
                            <tbody class="divide-y">
                                <tr v-for="(item) in items" :key="item.idx">
                                    <td class="px-2 py-1 align-top">{{item.qty}}</td>
                                    <td class="px-2 py-1">
                                        {{item.name}}
                                        <ul>
                                            <li v-for="(serial, i) in item.serialNumber" :key="i">SN: {{serial.sn}}</li>
                                        </ul>
                                    </td>
                                </tr>
                                <tr v-for="a in addRow" :key="a">
                                    <td class="px-2 py-3"></td>
                                    <td class="px-2 py-3"></td>
                                </tr>
                                <tr class="border-red-600 border-t">
                                    <td class="py-3"></td>
                                </tr>
                                <tr class="border-red-600 border-t">
                                    <td class="py-3"></td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="grid grid-cols-2 mt-2 mb-28">
                            <div class="col-span-1 text-center">Tanda Terima</div>
                            <div class="col-span-1 text-center">Hormat Kami</div>
                            
                        </div>
                       
                        <div class="mt-3 border p-5 flex items-center justify-between">
                            <img src="@/assets/kyowa.png" width="100" alt="">
                            <img src="@/assets/lutron.png" width="100" alt="">
                            <img src="@/assets/brunton.png" width="100" alt="">
                            <img src="@/assets/estwing.png" width="100" alt="">
                            <img src="@/assets/atlas.png" width="100" alt="">
                        </div>
                        <div class="flex mt-3">
                            <div>
                                <table>
                                    <tr>
                                        <td class="text-red-600">Tel</td>
                                        <td class="pl-3">022.7106162</td>
                                    </tr>
                                    <tr>
                                        <td class="text-red-600">Fax</td>
                                        <td class="pl-3">022.7274932</td>
                                    </tr>
                                </table>
                            </div>
                            <div class="ml-10">
                                <table v-if="company">
                                    <tr>
                                        <td class="text-red-600">Email</td>
                                        <td class="pl-3">{{company.email}}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-red-600">Web</td>
                                        <td class="pl-3">{{company.website}}</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { moment } from '../moment'
export default {
    props: ['data'],
    data () {
        return {
            imgURL: process.env.VUE_APP_ROOT_API,
            suratJalan: '',
            date: '',
            shipVia: '',
            shipTo: {
                name: '',
                address: '',
                contact: ''
            },
            items: [],
            customerPO: '',
        }
    },
    computed: {
        ...mapGetters({
            company: 'auth/company'
        }),
        addRow () {
            let row = this.items.length
            let t = 0
            if(row < 15) {
                t = 15 - row
                return t
            } else {
                return t
            }
        },
        totalHandling () {
            let t = 0
            t = this.shipmentCost + this.additionalChargers
            return t
        }
    },
    methods: {
        getDate (d) {
            const month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep','Oct', 'Nov', 'Des']
            const date = new Date(d)
            let dd = date.getDate()
            let mm = month[date.getMonth()]
            let yy = date.getFullYear()
            dd = checkTime(dd)
            function checkTime (i) {
                if(i < 10) {
                    i = `0${i}`
                }
                return i
            }
            return `${dd}-${mm}-${yy}`
        },
        getPaidDate (d) {
            return moment(d)
        }
    },
    watch: {
        data (val) {
            if(val) {
                this.suratJalan = val.suratJalanNo
                this.date = val.valiDate
                this.items = val.items
                this.customerPO = val.customerPO
                if(val.shipping.name) {
                    this.shipVia = val.shipping.name.name
                }
                if(val.shipTo) {
                    this.shipTo = val.shipTo
                }
            }
        }
    }
}
</script>