<template>
    <div>
        <div class="flex items-center justify-between border rounded-t-sm px-4 bg-white shadow-lg">
            <div>
                <stepper>
                    <li><button :class="{'active': delivery.status == 'Draft'}">DRAFT</button></li>
                    <li><button :class="{'active': delivery.status == 'Waiting'}">WAITING</button></li>
                    <li><button :class="{'active': delivery.status == 'Ready'}">READY</button></li>
                    <li><button :class="{'active': delivery.status == 'Delivery'}">DELIVERY</button></li>
                    <li><button :class="{'active': delivery.status == 'Done'}">DONE</button></li>
                    <li v-if="delivery.status == 'Cancelled'"><button :class="{'active': delivery.status == 'Cancelled'}">CANCELLED</button></li>
                </stepper>
            </div>
            <div v-if="delivery.status == 'Ready'">
                <!-- <button class="mr-1.5 h-7 border border-transparent px-2 text-teal-500 hover:text-gray-500 hover:border-gray-200 hover:bg-gray-100">PRINT</button> -->
                <button @click="onValidate" class="bg-teal-500 px-3 text-teal-50 h-7 mr-0.5 hover:bg-teal-600">VALIDATE</button>
            </div>
            <div v-if="delivery.status == 'Delivery'">
                <button @click="onPrint" class="mr-1.5 h-7 border border-transparent px-2 text-teal-500 hover:text-gray-500 hover:border-gray-200 hover:bg-gray-100">PRINT</button>
                <button @click="onDelivery" class="bg-teal-500 px-3 text-teal-50 h-7 mr-0.5 hover:bg-teal-600">DELIVERED</button>
            </div>
        </div>
        
        <div class="m-5">
            <div class="bg-white border rounded-t-sm">
                <div class="flex justify-between items-center">
                    <div class="flex whitespace-nowrap cursor-pointer relative font-medium">
                        <div @click="onTab('overview')" :class="{'border-red-500 text-red-500 hover:border-red-500': tab =='overview'}" class="border-b-2 px-5 inline-block text-center pt-3 pb-2 border-transparent hover:border-gray-200 hover:text-red-600">OVERVIEW</div>
                        <div @click="onTab('task')" :class="{'border-red-500 text-red-500 hover:border-red-500': tab =='task'}" class="border-b-2 px-5 inline-block text-center pt-3 pb-2 border-transparent hover:border-gray-200 hover:text-red-600">TASK & NOTES <span class="py-0.5 px-1 rounded-sm bg-gray-800 text-gray-50 ml-2 text-xs min-w-min w-5 inline-block text-center">{{task}}</span></div>
                    </div>
                </div>
            </div>

            <!-- OVERVIEW -->
            <div v-show="tab=='overview'" class="animated fadeIn faster">
                
                <div class="grid grid-cols-9 p-5 divide-x bg-white border border-t-0 border-b-0">
                    <div class="col-span-5">
                        <div class="grid grid-cols-3 leading-none mb-5">
                            <div class="col-span-1">
                                <div class="text-lg font-medium">{{delivery.deliveryNo}}</div>
                                <div class="text-gray-400 text-xs">DELIVERY ORDER#</div>
                            </div>
                            <div class="col-span-1 leading-none">
                                <div class="text-lg font-medium">{{delivery.salesNo}}</div>
                                <div class="text-gray-400 text-xs">SALES ORDER #</div>
                            </div>
                            <div class="col-span-1 leading-none">
                                <div v-if="delivery.suratJalanNo" class="text-lg font-medium">{{delivery.suratJalanNo}}</div>
                                <div v-else class="text-lg font-medium">--</div>
                                <div class="text-gray-400 text-xs">SURAT JALAN #</div>
                            </div>
                        </div>
                        <div class="mb-3" v-if="delivery.shipTo">
                            <label for="" class="font-medium">DELIVERY ADDRESS</label>
                            <div class="text-base font-medium">{{delivery.shipTo.name}}</div>
                            <div v-if="delivery.shipTo.address">
                                <div>{{delivery.shipTo.address.street}}</div>
                                <div>{{delivery.shipTo.address.street2}}</div>
                                <div>{{delivery.shipTo.address.subdistrictName}} {{delivery.shipTo.address.cityName}} {{delivery.shipTo.address.zip}}</div>
                                <div>{{delivery.shipTo.address.provinceName}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-span-4 pl-5">
                        <!-- ON EDIT -->
                        <div v-show="isEdited">
                            <div class="mb-5">
                                <div class="grid grid-cols-2 gap-5">
                                    <div class="col-span-1">
                                        <label for="" class="font-medium">SCHEDULED DATE</label>
                                        <div class="mt-2">
                                            <date-picker v-model="form.scheduled" :masks="masks">
                                                <template v-slot="{inputValue, inputEvents}">
                                                    <div class="flex">
                                                        <span class="h-9 flex items-center justify-center w-10 bg-gray-100 border rounded-l-sm px-5"><i class="icon-calendar3"></i></span>
                                                        <input :disabled="status" :value="inputValue" v-on="inputEvents" type="text" class="form-control rounded-tl-none rounded-bl-none border-l-0 text-blue-500">
                                                    </div>
                                                </template>
                                            </date-picker>
                                        </div>
                                    </div>
                                    <div class="col-span-1">
                                        <label for="">SHIPMENT COST</label>
                                        <autonumeric :disabled="true" v-model="form.shipping.shipmentCost" :options="{emptyInputBehavior: 'null', modifyValueOnWheel: false}" class="form-control text-right mt-2"></autonumeric>
                                    </div>
                                </div>
                            </div>
                            <div class="grid grid-cols-2 gap-5 mb-5">
                                <div class="col-span-1">
                                    <label class="font-medium" for="">KURIR / EXPEDISI</label>
                                    <div class="flex flex-col mt-2">
                                        <select-2 :disabled="true" v-model="form.shipping.shipmentMethodId" :options="shippings" class="form-control" :placeholder="'Select kurir'">
                                            <option value=""></option>
                                        </select-2>
                                    </div>
                                </div>
                                <div class="col-span-1">
                                    <label class="font-medium" for="">SHIPMENT SERVICE</label>
                                    <div class="flex flex-col mt-2">
                                        <select-2 :disabled="true" ref="shipmentService" v-model="form.shipping.shipmentService" :options="shipmentServices" class="form-control" :placeholder="'Select kurir'">
                                            <option value=""></option>
                                        </select-2>
                                    </div>
                                </div>
                            </div>
                            <div class="grid grid-cols-2 gap-5">
                                <div class="col-span-1">
                                    <div>BOOKING CODE</div>
                                    <input v-model="form.shipping.bookingCode" type="text" class="form-control mt-2">
                                </div>
                                <div class="col-span-1">
                                    <div>TRACKING NUMBER</div>
                                    <input v-model="form.shipping.trackingNumber" type="text" class="form-control mt-2">
                                </div>
                            </div>
                        </div>

                        <!-- ON SAVE -->
                        <div v-show="!isEdited">
                            <div class="mb-5">
                                <div class="grid grid-cols-2 gap-5">
                                    <div class="col-span-1">
                                         <label for="" class="font-medium">SCHEDULED DATE</label>
                                        <div>{{getDate(delivery.scheduled)}}</div>
                                    </div>
                                    <div class="col-span-1">
                                         <label for="" class="font-medium">SHIPMENT COST</label>
                                        <div>{{Intl.NumberFormat('en-US', {minimumFractionDigits: 2}).format(form.shipping.shipmentCost)}}</div>
                                    </div>

                                </div>
                            </div>
                            <div class="grid grid-cols-2 gap-5 mb-3">
                                <div class="col-span-1">
                                    <label class="font-medium" for="">KURIR / EXPEDISI</label>
                                    <div v-if="form.shipping">
                                        <div v-if="form.shipping.name">{{form.shipping.name.name}}</div>
                                        <div v-else>--</div>
                                    </div>
                                </div>
                                <div class="col-span-1">
                                    <label class="font-medium" for="">SHIPMENT SERVICE</label>
                                    <div v-if="form.shipping">
                                        <div v-if="form.shipping.shipmentService">{{form.shipping.shipmentService}}</div>
                                        <div v-else>--</div>
                                    </div>
                                </div>
                            </div>
                            <div class="grid grid-cols-2 gap-5 mb-3">
                                <div class="col-span-1">
                                    <div>BOOKING CODE</div>
                                    <div v-if="form.shipping.bookingCode" class="flex items-center">
                                        <div>{{form.shipping.bookingCode}}</div>
                                    </div>
                                    <div v-else>--</div>
                                </div>
                                <div class="col-span-1">
                                    <div>TRACKING NUMBER</div>
                                    <div v-if="form.shipping.trackingNumber" class="flex items-center">
                                        <div>{{form.shipping.trackingNumber}}</div>
                                    </div>
                                    <div v-else>--</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <table class="w-full table-fixed bg-white">
                    <thead class="border border-blue-500">
                        <tr class="bg-blue-500 border-b">
                            <th class="py-2.5 pl-5 pr-2  sticky z-10 text-blue-50 bg-blue-500" style="width: 5%; top: 93px">#</th>
                            <th class="py-2.5 px-2 sticky z-10 text-blue-50 bg-blue-500" style="width: 48%; top: 93px">ITEM</th>
                            <th class="py-2.5 px-2 sticky z-10 text-blue-50 text-right bg-blue-500" style="width: 15%; top: 93px">DEMAND</th>
                            <th class="py-2.5 px-2 sticky z-10 text-blue-50 text-right bg-blue-500" style="width: 15%; top: 93px">RESERVED</th>
                            <th class="py-2.5 px-2 sticky z-10 text-blue-50 text-right bg-blue-500" style="width: 2%; top: 93px"></th>
                            <th class="py-2.5 px-2 sticky z-10 text-blue-50 text-right bg-blue-500" style="width: 15%; top: 93px">DONE</th>
                            <th class="py-2.5 px-5 pr-5 sticky z-10 text-blue-50 text-center bg-blue-500" style="width: 15%; top: 93px">SN</th>
                        </tr>
                    </thead>
                    <tbody ref="body" class="border border-b-0">
                        <tr v-for="(item, i) in form.items" :key="i" @click="onEdit(i)" class="border-b hover:bg-gray-100 group cursor-pointer" :class="[item.qty > item.done || item.qty < item.done || item.qty > item.stock ? 'text-red-500': 'text-green-600']">
                            <td :ref="'td'+i" class="pl-5 h-9 border-r relative" :class="{'bg-gray-100': item.edited}">
                                <div v-show="item.edited" :ref="'tick'+i" class="absolute -left-px -top-px w-2 bg-teal-500"></div>
                                {{i+1}}
                            </td>
                            <td class="px-2 h-9 border-r border-transparent truncate" :class="{'bg-gray-100 cursor-not-allowed': item.edited}">{{item.name}}</td>
                            <td class="px-2 h-9 border-r border-transparent text-right" :class="{'bg-gray-100 cursor-not-allowed': item.edited}">{{item.qty}}</td>
                            <td class="px-2 h-9 border-r border-transparent text-right" :class="{'bg-gray-100 cursor-not-allowed': item.edited}">{{item.reserved}}</td>
                            <td class="h-9 px-1 border-r border-transparent text-center" :class="{'bg-gray-100': item.edited}">
                                <a tabindex="-1" href="#" :class="[item.qty > item.stock ? 'text-red-500':'text-green-500']"><i class="icon-chart"></i></a>
                            </td>
                            <td class="border-r">
                                <autonumeric
                                    :disabled="status"
                                    :ref="'done'+i"
                                    :class="[{'group-hover:bg-white': item.edited && !status}, {'bg-red-500 text-red-50 group-hover:bg-red-500': item.err}]"
                                    @blur.native="onCheckSerialNumber(i)"
                                    @keydown.native.enter="onEnter(i)"
                                    v-model="item.done"
                                    class="h-9 w-full focus:outline-none px-2 text-right group-hover:bg-gray-100"
                                    :options="['integer', {modifyValueOnWheel : false}]" 
                                >
                                </autonumeric>
                            </td>
                            <td class="text-center">
                                <a tabindex="-1" @click.stop="showSerialNumber(i)" href="#" class="h-9 flex items-center justify-center" :class="[item.isSerialNumber ? 'text-gray-500 hover:text-blue-500':'text-gray-300 cursor-not-allowed pointer-events-none']"><i class="icon-list-unordered"></i></a>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="6" class="h-9 px-2 text-right border-r">{{done}}</td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
             <!-- TASK & NOTE -->
            <div v-show="tab == 'task'" class="bg-white border border-t-0 rounded-b-sm animated fadeIn faster">
                <task-manager v-model="task" :documentName="delivery.deliveryNo"></task-manager>   
            </div>
        </div>

        <!-- MODAL IMMEDIATE -->
        <modal :show="modalImmediate">
            <div class=" w-2/5 bg-white rounded-sm border">
                <div class="px-5 flex items-center py-3 border-b">IMMEDIATE TRANSFER?</div>
                <div class="p-5 border-b">
                    You have not recorded done quantities yet, by clicking on apply App will process all the quantities.
                </div>
                <div class="px-5 py-2 text-right">
                    <button @click="modalImmediate = false" class="h-9 rounded-sm px-5 mr-2 border border-transparent hover:border-gray-200 hover:bg-gray-100">CANCEL</button>
                    <button @click="immediateTransfer" class="bg-teal-500 h-9 rounded-sm px-5 text-teal-50">APPLY</button>
                </div>
            </div>
        </modal>

        <!-- MODAL SERIAL NUMBER -->
        <modal :show="modalSerialNumber">
            <div class="w-2/5 bg-white rounded-sm border">
                <form @submit.prevent="onSubmitSerialNumber">
                    <div class="flex items-center justify-between px-5 py-3 border-b">
                        <div>SERIAL NUMBER</div>
                    </div>
                    <div class="p-5">
                        <div class="mb-3">{{formSerialNumber.name}}</div>
                        <table class="w-full">
                            <tr v-for="(sn, i) in formSerialNumber.serialNumber" :key="i">
                                <td class="py-1" style="width:6%">#{{i+1}}<td>
                                <td class="py-1">
                                    <input :disabled="status" v-model="sn.sn" :ref="'sn'+i" type="text" class="form-control" :class="{'border-red-500': formSerialNumber.errSerialNumber[i].err}" placeholder="Serial Number">
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="py-2 text-right px-5 border-t">
                        <button @click="modalSerialNumber=false" type="button" class="h-9 px-5 rounded-sm hover:bg-gray-100 hover:border-gray-200 mr-2">CANCEL</button>
                        <button :disabled="status" type="submit" class="h-9 px-5 rounded-sm text-teal-50" :class="[status ? 'bg-teal-400 cursor-not-allowed':'hover:bg-teal-600 bg-teal-500']">APPLY</button>
                    </div>
                </form>
            </div>
        </modal>

        <!-- MODAL BACK ORDER -->
        <modal :show="modalBackOrder">
            <div class="w-2/5 bg-white rounded-sm border">
                <div class="py-3 px-5 flex border-b">
                    <div>CREATE BACKORDER?</div>
                </div>
                <div class="p-5">
                    You have processed less products than the initial demand.
                    <ul class="p-2 bg-blue-100 rounded-sm mt-1.5">
                        <li>Create a backorder if you expect to process the remaining products later.</li>
                        <li>Do not create a backorder if you will not process the remaining products.</li>
                    </ul>
                </div>
                <div>
                    <div class="py-2 text-right px-5 border-t">
                        <button @click="modalBackOrder= false" type="button" class="h-9 px-5 rounded-sm hover:bg-gray-100 hover:border-gray-200 mr-2">CANCEL</button>
                        <button type="submit" class="bg-teal-500 h-9 px-5 rounded-sm text-teal-50 hover:bg-teal-600 mr-2">NO BACKORDER</button>
                        <button @click="onCreateBackOrder" type="submit" class="bg-teal-500 h-9 px-5 rounded-sm text-teal-50 hover:bg-teal-600">CREATE BACKORDER</button>
                    </div>
                </div>
            </div>
        </modal>

        <!-- MODAL DELIVER -->
        <modal :show="modalDelivered">
            <form @submit.prevent="onSubmitDelivered" class="w-2/5 bg-white rounded-sm border">
                <div class="flex items-center justify-between border-b px-5 py-3">
                    <div>DELIVERED</div>
                </div>
                <div class="m-5">
                    <label for="">TRACKING NUMBER</label>
                    <input v-model="formTrackingNumber.trackingNumber" @keydown="formTrackingNumber.err = false" type="text" class="form-control mt-2" :class="{'border-red-500': formTrackingNumber.err}">
                </div>
                <div class="py-2 text-right px-5 border-t">
                    <button @click="modalDelivered=false" type="button" class="h-9 px-5 rounded-sm hover:bg-gray-100 hover:border-gray-200 mr-2">CANCEL</button>
                    <button type="submit" class="h-9 px-5 rounded-sm text-teal-50 hover:bg-teal-600 bg-teal-500">APPLY</button>
                </div>
            </form>
        </modal>
        <div class="hidden">
            <surat-jalan :data="delivery" id="suratJalan"></surat-jalan>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import stepper from '../../../components/stepper.vue'
import Autonumeric from '../../../components/Autonumeric.vue'
import BreadcrumbManager from '../../../mixins/breadcrumbsManager.vue'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import Modal from '../../../components/Modal.vue'
import Swal from 'sweetalert2/dist/sweetalert2'
import Select2 from '../../../components/Select2.vue'
import {EventBus} from '../../../event-bus'
import {moment} from '../../../moment'
import SuratJalan from '../../../components/suratJalanPdf.vue'
import TaskManager from '../../../components/taskManager.vue'
import printJS from 'print-js'
export default {
    components: {stepper, Autonumeric, Modal, DatePicker, Select2, TaskManager, SuratJalan},
    mixins: [BreadcrumbManager],
    data () {
        return {
            isEdited: false,
            modalImmediate: false,
            modalSerialNumber: false,
            modalBackOrder: false,
            modalDelivered: false,
            shippings: [],
            masks: {
                input: 'DD/MM/YYYY'
            }, 
            delivery:'',
            form: {
                deliveryNo: '',
                salesId: '',
                customerId: '',
                warehouseId: '',
                scheduled: '',
                items: [],
                status: '',
                shipping: {
                    shipmentMethodId: '',
                    shipmentService: '',
                    shipmentCost: 0,
                    bookingCode: '',
                    trackingNumber: ''   
                }
            },
            tab: 'overview',
            task: 0,
            formSerialNumber : {
                index: '',
                name: '',
                serialNumber: [],
                errSerialNumber: []
            },
            formTrackingNumber: {
                trackingNumber: '',
                err: ''
            },
        }
    },
    mounted () {
        this.setButtonText('EDIT')
        this.getData(true)
        EventBus.$on('onClick', this.onEditDelivery)
        EventBus.$on('onCancel', this.onDiscard)
        window.addEventListener('click', this.onClose)
    },
    destroyed () {
        if(this.isEdited) {
            EventBus.$off('onClick', this.onSaveDelivery)
        } else {
            EventBus.$off('onClick', this.onEditDelivery)
        }
        EventBus.$off('onCancel', this.onDiscard)
        window.removeEventListener('click', this.onClose)
    },
    computed: {
        status () {
            if(this.form.status == 'Delivery' || this.form.status == 'Done' || this.form.status == 'Cancelled') {
                return true
            }
            return false
        },
        done () {
            let sum = 0
            for (let i=0; i<this.form.items.length; i++) {
                sum += this.form.items[i].done
            }
            return sum
        },
        shipmentServices () {
            let services =  this.shippings.find(obj => obj.id == this.form.shipping.shipmentMethodId)
            if(services) {
                return services.services.map(obj => {
                    obj.id = obj.name,
                    obj.text = obj.name
                    return obj
                })
            }
            return []
        },
    },
    methods: {
        onTab (i) {
            this.tab = i
        },
        getData (v) {
            this.form.items = []
            axios.get('/delivery/detail/'+this.$route.params.id)
            .then(res => {
                this.shippings = res.data.shippings
                this.delivery = res.data.delivery
                this.form.customerId = res.data.delivery.customerId
                this.form.deliveryNo = res.data.delivery.deliveryNo
                this.form.salesId = res.data.delivery.salesId
                this.form.scheduled = res.data.delivery.scheduled
                this.form.status = res.data.delivery.status
                this.form.warehouseId = res.data.delivery.warehouseId
                for(let i=0; i < this.delivery.items.length; i++) {
                    const el = this.delivery.items[i]
                    let reserved;
                    if(el.qty <= el.stock) {
                        reserved = el.qty
                    } else {
                        if(el.stock.length > 0) {
                            reserved = el.stock[0]
                        } else {
                            reserved = 0
                        }
                    }
                    this.form.items.push({idx: el.idx, name: el.name, isSerialNumber: el.isSerialNumber, serialNumber: el.serialNumber || [], productId: el.productId, qty: el.qty, stock: el.stock, reserved: reserved, done: el.done, edited: false, err: false})
                }
                if(v) {
                    this.pushBreadcrumb(
                        {text: this.delivery.deliveryNo}
                    )
                }
                this.$nextTick(() => {
                    if(this.delivery.shipping.shipmentMethodId) {
                        this.form.shipping = res.data.delivery.shipping
                        this.$nextTick(() => {
                            this.$refs.shipmentService.update()
                        })
                    } else {
                        this.form.shipping = res.data.delivery.shipping
                    }
                })
            })
        },
        onEdit (i) {
            this.onClear()
            this.form.items[i].edited = true
            this.form.items[i].err = false
            let h = this.$refs['td'+i][0].clientHeight +2
            this.$refs['tick'+i][0].style.height = h+'px'
            this.$refs['done'+i][0].$el.focus()
            if(this.form.status != 'Delivery' && this.form.status != 'Done') {
                this.isEdited = true
            }
        },
        onEnter (i) {
            let index = i+1
            if(index < this.form.items.length) {
                this.onEdit(index)
            } else {
                this.$refs['done'+i][0].$el.blur()
            }
        },
        onCheckSerialNumber (i) {
            let el = this.form.items[i]
            if(el.isSerialNumber && el.serialNumber.length == 0 && el.done > 0) {
                this.modalSerialNumber = true
                this.formSerialNumber.index = i
                this.formSerialNumber.name = el.name
                let done = el.done
                for(let i=0; i < done; i++) {
                    this.formSerialNumber.serialNumber.push({sn: ''})
                    this.formSerialNumber.errSerialNumber.push({err: ''})
                }
            }
            if(el.isSerialNumber && el.serialNumber.length > 0 && el.serialNumber.length < el.done) {
                this.modalSerialNumber = true
                this.formSerialNumber.index = i
                this.formSerialNumber.name = el.name
                this.formSerialNumber.serialNumber = el.serialNumber
                for (let i=0; i < el.serialNumber.length; i++) {
                    this.formSerialNumber.errSerialNumber.push({err: ''})
                }
                let a = el.done - el.serialNumber.length
                for(let i=0; i< a; i++) {
                    this.formSerialNumber.serialNumber.push({sn: ''})
                    this.formSerialNumber.errSerialNumber.push({err: ''})
                }
            }
            if(el.isSerialNumber && el.serialNumber.length > 0 && el.serialNumber.length > el.done) {
                let a = el.serialNumber.length - el.done
                el.serialNumber.splice(el.done, a)
                this.modalSerialNumber = true
                this.formSerialNumber.index = i
                this.formSerialNumber.name = el.name
                this.formSerialNumber.serialNumber = el.serialNumber
                for (let i=0; i < el.serialNumber.length; i++) {
                    this.formSerialNumber.errSerialNumber.push({err: ''})
                }
            }
        },
        onSubmitSerialNumber () {
            let app = this
            for(let i=0; i < this.formSerialNumber.serialNumber.length; i++) {
                let el = this.formSerialNumber.serialNumber[i]
                if(!el.sn) {
                    this.formSerialNumber.errSerialNumber[i].err = true
                } else {
                    this.formSerialNumber.errSerialNumber[i].err = false
                }
            }

            if(emptySerialNumber()) {
                let i = this.formSerialNumber.index
                this.form.items[i].serialNumber = this.formSerialNumber.serialNumber
                this.modalSerialNumber = false
            }

            function emptySerialNumber () {
                for (let i=0; i < app.formSerialNumber.serialNumber.length; i++) {
                    const el = app.formSerialNumber.serialNumber[i]
                    if(!el.sn) {
                        return false
                    }
                } 
                return true
            }

        },
        showSerialNumber (index) {
            this.modalSerialNumber = true
            let el = this.form.items[index]
            this.formSerialNumber.index = index
            this.formSerialNumber.name = el.name
            if(el.serialNumber.length > 0) {
                this.formSerialNumber.serialNumber = el.serialNumber
                for(let i=0; i < el.serialNumber.length; i++) {
                    this.formSerialNumber.errSerialNumber.push({err: ''})
                }
            }
            if(el.serialNumber.length == 0) {
                let a = el.done
                for (let i=0; i < a; i++) {
                    this.formSerialNumber.serialNumber.push({sn: ''})
                    this.formSerialNumber.errSerialNumber.push({err: ''})
                }
            }
        },
        onClear () {
            for(let i=0; i < this.form.items.length; i++) {
                this.form.items[i].edited = false
            }
        },

        onClose (e) {
            if(!e || !this.$refs.body.contains(e.target)) {
                this.onClear()
            }
        },

        onValidate () {
            if(this.isEmpty()) {
                this.modalImmediate = true
                return
            }
            for(let i=0; i < this.form.items.length; i++) {
                const el = this.form.items[i]
                if(el.done > el.reserved) {
                    el.err = true
                }
            }
            for(let i=0; i < this.form.items.length; i++) {
                const el = this.form.items[i]
                if(el.done > el.reserved) {
                    return
                }
            }
            for (let i=0; i < this.form.items.length; i++) {
                const el = this.form.items[i]
                if(el.isSerialNumber && el.serialNumber.length == 0 && el.done > 0 ||  el.isSerialNumber && this.isSerialNumberEmpty(el.serialNumber)) {
                    this.showSerialNumber(i)
                    return;
                }
            }
            for(let i=0; i < this.form.items.length; i++) {
                const el = this.form.items[i]
                if(el.qty > el.done) {
                    this.modalBackOrder = true
                    return
                }
            }
            axios.post('/delivery/validate/'+this.$route.params.id, this.form.items)
            .then(() => {
                this.getData(false)
            })
            
        },
        onCreateBackOrder () {
            let backorder = []
            for(let i=0; i < this.form.items.length; i++) {
                const el = this.form.items[i]
                if(el.done < el.qty) {
                    let demand = el.qty - el.done
                    backorder.push({idx: el.idx, productId: el.productId, isSerialNumber: el.isSerialNumber, serialNumber: [], qty: demand, reserved: 0, done: 0})
                } 
            }
            let form = {
                _id: this.delivery.salesId,
                customerId: this.delivery.customerId,
                items: backorder,
                status: 'Waiting',
                shipping: this.delivery.shipping
            }
            axios.post('/delivery/backorder', form)
            .then(() => {
                axios.post('/delivery/validate/'+this.$route.params.id, this.form.items)
                .then(() => {
                    this.getData(false)
                    this.modalBackOrder = false
                })

            })
        },
        immediateTransfer () {
            for (let i=0; i < this.form.items.length; i++) {
                let el = this.form.items[i]
                if(el.qty <= el.reserved) {
                    el.done = el.qty
                } else {
                    el.done = el.reserved
                }
            }
            if(this.isEmpty()) {
                this.modalImmediate = false
                Swal.fire({
                    title: 'Error Stock',
                    text: 'Some items are out of stock, please check again',
                    type: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonClass:'bg-blue-500 px-5 rounded-sm focus:outline-none h-9 text-white hover:bg-blue-600',
                })
                return
            }
            this.modalImmediate = false
            this.onValidate()
        },
        isEmpty () {
            for(let i of this.form.items) {
                if(i.done) {
                    return false
                }
            }
            return true
        },
        isSerialNumberEmpty (obj) {
            for(let i of obj) {
                if(!i.sn) {
                    return true
                }
            }
            return false
        },
        onDelivery () {
            this.modalDelivered = true
            this.formTrackingNumber.trackingNumber = this.delivery.trackingNumber
        },
        onSubmitDelivered () {
            if(!this.formTrackingNumber.trackingNumber) {
                this.formTrackingNumber.err = true
            }
            if(this.formTrackingNumber.trackingNumber) {
                axios.put('/delivery/tracking/'+this.delivery._id, this.formTrackingNumber)
                .then(() => {
                    this.modalDelivered = false
                    this.getData()
                })
            }
        },
        onEditDelivery () {
            this.isEdited = true
        },
        onSaveDelivery () {
            for (let i=0; i < this.form.items.length; i++) {
                const el = this.form.items[i]
                if(el.isSerialNumber && el.serialNumber.length == 0 && el.done > 0 ||  el.isSerialNumber && this.isSerialNumberEmpty(el.serialNumber)) {
                    this.showSerialNumber(i)
                    return;
                }
            }
            axios.put('/delivery/update/'+this.delivery._id, this.form)
            .then(() => {
                this.isEdited = false
                this.getData(false)
            })
        },
        onDiscard () {
            this.isEdited = false
            this.getData(false)
        },
        getDate (d) {
            return moment(d)
        },
        onPrint () {
            let suratJalanNo = this.delivery.suratJalanNo
            let title = suratJalanNo.replace(/\//g, ".") +" ("+ this.delivery.customer.displayName+")"
            document.title = title
            this.$nextTick(() => {
                printJS({
                    printable: 'suratJalan', 
                    type: 'html',
                    css: 'https://unpkg.com/tailwindcss@^2/dist/tailwind.min.css',
                    scanStyles: false,
                    documentTitle: title,
                    onPrintDialogClose: () => this.onCancelPrint()
                })
            })
        },
        onCancelPrint () {
            document.title = 'APP DUTAPERSADA'
        },
    },
    watch: {
        modalSerialNumber (val) {
            if(!val) {
                this.formSerialNumber.name = ''
                this.formSerialNumber.index = ''
                this.formSerialNumber.serialNumber = []
                this.formSerialNumber.errSerialNumber = []
            }
        },
        isEdited (val) {
            if(val) {
                this.setButtonText('SAVE')
                this.setDiscard(true)
                this.setDiscardText('DISCARD')
                EventBus.$off('onClick', this.onEditDelivery)
                EventBus.$on('onClick', this.onSaveDelivery)
            } else {
                this.setButtonText('EDIT')
                this.setDiscard(false)
                this.setDiscardText()
                EventBus.$off('onClick', this.onSaveDelivery)
                EventBus.$on('onClick', this.onEditDelivery)
            }
        }
    }
}
</script>

<style>
    .vc-popover-content-wrapper {
        z-index: 50 !important;
    }
</style>